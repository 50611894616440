@font-face {
	font-family: "eflex-icons";
	font-display: block;
	src: url("/icons/eflex-icons.woff2?bf3d428c9de85a414ef7119a58ccdd03") format("woff2");
}

.icon {
	line-height: 1;
}

.icon:before {
	font-family: eflex-icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.icon-add-plus:before {
	content: "\f101";
}
.icon-carousel-next:before {
	content: "\f102";
}
.icon-carousel-prev:before {
	content: "\f103";
}
.icon-checkmark:before {
	content: "\f104";
}
.icon-clear:before {
	content: "\f105";
}
.icon-complex-component:before {
	content: "\f106";
}
.icon-drag-drop:before {
	content: "\f107";
}
.icon-dynamic:before {
	content: "\f108";
}
.icon-fa-minus:before {
	content: "\f109";
}
.icon-fa-plus:before {
	content: "\f10a";
}
.icon-fas-arrow-down:before {
	content: "\f10b";
}
.icon-fas-arrow-up:before {
	content: "\f10c";
}
.icon-fas-chevron-left:before {
	content: "\f10d";
}
.icon-fas-chevron-right:before {
	content: "\f10e";
}
.icon-fas-exclamation-circle:before {
	content: "\f10f";
}
.icon-fas-exclamation-triangle:before {
	content: "\f110";
}
.icon-fas-exclamation:before {
	content: "\f111";
}
.icon-folder:before {
	content: "\f112";
}
.icon-fullscreen-enter:before {
	content: "\f113";
}
.icon-fullscreen-exit:before {
	content: "\f114";
}
.icon-jem-admin:before {
	content: "\f115";
}
.icon-language:before {
	content: "\f116";
}
.icon-link:before {
	content: "\f117";
}
.icon-lock:before {
	content: "\f118";
}
.icon-log-out:before {
	content: "\f119";
}
.icon-next-arrow:before {
	content: "\f11a";
}
.icon-next-double-arrow:before {
	content: "\f11b";
}
.icon-play:before {
	content: "\f11c";
}
.icon-previous-arrow:before {
	content: "\f11d";
}
.icon-previous-double-arrow:before {
	content: "\f11e";
}
.icon-question:before {
	content: "\f11f";
}
.icon-right-panel-arrow:before {
	content: "\f120";
}
.icon-schedule:before {
	content: "\f121";
}
.icon-search:before {
	content: "\f122";
}
.icon-trash:before {
	content: "\f123";
}
.icon-unlink:before {
	content: "\f124";
}
.icon-unlock:before {
	content: "\f125";
}
.icon-wie-group:before {
	content: "\f126";
}
.icon-wie-ungroup:before {
	content: "\f127";
}
.icon-x:before {
	content: "\f128";
}
